import React, { Fragment, useContext, useEffect, useState } from 'react';


import RegistrantsContext from 'context/Registrants';

import { eventTracking } from 'utils/eventTracking';

const Options = ({ quotesById, resetQuotes, totalSignupFeesById }) => {
  const registrantsById = useContext(RegistrantsContext);

  const [optIn, setOptIn] = useState(true);
  const [optInIds, setOptInIds] = useState([]);
  const [optInQuotes, setOptInQuotes] = useState([]);

  useEffect(() => {
    // Reset the hidden inputs in Classic,
    // when a required fee has been modified
    // after requesting quoted estimates.
    const yesIsChecked = document.getElementsByClassName('is-checked');

    if (resetQuotes) {
      // Uncheck the `yes` radio inputs,
      // since the quoted estimate may have changed.
      Object.values(yesIsChecked).forEach(el => (el.checked = false));

      setOptInIds([]);
      setOptInQuotes([]);

      // Hidden input elements are inside classic signup flow - fees step
      document.getElementById('registrant_ids').setAttribute('value', '');
      document.getElementById('regsaver_amounts').setAttribute('value', '');
    }
  }, [resetQuotes]);

  /**
   * @function handleRegsaverChange
   *
   * Update arrays for opting in to Regsaver.
   * Arrays for registrant ID's and quotes should share the same indexes
   *
   */
  const handleRegsaverChange = e => {
    const { id, value } = e.target;
    // Input id follows this format:
    // id="yes_123"
    const activeId = id.split('_')[1];

    if (id.includes('yes')) {
      optInIds.push(activeId);
      optInQuotes.push(value);
    } else if (id.includes('no')) {
      const index = optInIds.indexOf(activeId);
      // Check for cases where user first chooses to opt-in,
      // then chooses to opt-out, during the same session
      if (index > -1) {
        optInIds.splice(index, index + 1);
        optInQuotes.splice(index, index + 1);
      }
    }

    setOptInIds(optInIds);
    setOptInQuotes(optInQuotes);

    // Update the required disclaimer acknowledgement.
    // Checked box is only required if there is at least one opt-in.
    optInIds.length > 0 ? setOptIn(true) : setOptIn(false);

    // Hidden input elements are inside classic signup flow - fees step
    document.getElementById('registrant_ids').setAttribute('value', optInIds);
    document
      .getElementById('regsaver_amounts')
      .setAttribute('value', optInQuotes);
  };

  return (
    <>
      <div className="Grid-cell u-sizeFull u-sm-size2of3">
        { !!Object.keys(registrantsById) &&
          Object.values(registrantsById).map(registrant => (
            <Fragment key={ registrant.id }>
              <strong>{ `${registrant.first} ${registrant.last}` }</strong>

              <div className="Checkbox Checkbox--radio">
                <input
                  className="Checkbox-input is-checked"
                  data-testid={ `yes_radio_${registrant.id}` }
                  disabled={ resetQuotes || !quotesById[registrant.id] }
                  id={ `yes_${registrant.id}` }
                  name={ `regsaver_${registrant.id}` }
                  onChange={ handleRegsaverChange }
                  onClick={ () =>
                    eventTracking(window.dataLayer, 'Purchased Insurance')
                  }
                  required
                  type="radio"
                  value={ `${quotesById[registrant.id]}` }
                />
                <label
                  className="Checkbox-label"
                  htmlFor={ `yes_${registrant.id}` }
                >
                  Yes, I would like to protect{ ' ' }
                  { `${registrant.first} ${registrant.last}'s` }{ ' ' }
                  { totalSignupFeesById[registrant.id] > 0 &&
                    `$${totalSignupFeesById[registrant.id]}` }{ ' ' }
                  registration fee{ ' ' }
                  { !resetQuotes && !!quotesById[registrant.id] && (
                    <Fragment>
                      <span>for</span>{ ' ' }
                      <strong>{ `$${quotesById[registrant.id]}` }</strong>
                    </Fragment>
                  ) }
                </label>
              </div>

              <div className="Checkbox Checkbox--radio">
                <input
                  className="Checkbox-input"
                  data-testid={ `no_radio_${registrant.id}` }
                  id={ `no_${registrant.id}` }
                  name={ `regsaver_${registrant.id}` }
                  onChange={ handleRegsaverChange }
                  onClick={ () =>
                    eventTracking(window.dataLayer, 'Declined Insurance')
                  }
                  required
                  type="radio"
                  value={ `${quotesById[registrant.id]}` }
                />
                <label className="Checkbox-label" htmlFor={ `no_${registrant.id}` }>
                  No, I choose not to protect{ ' ' }
                  { `${registrant.first} ${registrant.last}'s` } fee
                </label>
              </div>
            </Fragment>
          )) }
      </div>
    </>
  );
};

export default Options;
