import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import ErrorMessage from 'components/ErrorMessage';
import Logos from 'components/Logos';
import Options from 'components/Options';

import RegistrantsContext from 'context/Registrants';

import buildXMLQuote from 'utils/buildXMLQuote';
import { radios, textFields } from 'utils/calculateFees';
import {
  checkCompleteRequiredFees,
  checkCompleteAddress,
} from 'utils/formHelpers';
import states from 'utils/usStates';
import Disclaimer from 'components/Disclaimer';

const Address = ({ requiredFields, totalSignupFeesById }) => {
  const registrantsById = useContext(RegistrantsContext);

  const defaultAddress = {
    address: '',
    city: '',
    state: '',
    zip: '',
  };

  const [address, setAddress] = useState(defaultAddress);
  const [addressError, setAddressError] = useState(false);
  const [invalidData, setinvalidData] = useState(false);
  const [isInFlight, setIsInFlight] = useState(false);
  const [quotesById, setQuotesById] = useState({});
  const [resetQuotes, setResetQuotes] = useState(false);

  useEffect(() => {
    setAddress(findAddress(registrantsById));
  }, [registrantsById]);

  useEffect(() => {
    // This is for Classic
    // Watch for any additional changes to the total required fees
    // after initial quoted estimates have been requested.
    radios.forEach(input => {
      input.addEventListener('change', handleResetQuotes);
    });

    textFields.forEach(input => {
      input.addEventListener('keyup', handleResetQuotes);
    });

    return () => {
      radios.forEach(input => {
        input.removeEventListener('change', handleResetQuotes);
      });

      textFields.forEach(input => {
        input.removeEventListener('keyup', handleResetQuotes);
      });
    };
  });

  //
  // ─── API CALLS ──────────────────────────────────────────────────────────────────
  //

  const fetchQuote = (reqBody, id) => {
    // Proxy for API requests without CORS enabled
    const proxyUrl =
      process.env.REACT_APP_ENV === 'staging'
        ? 'https://us-central1-staging-205121.cloudfunctions.net/corsProxy'
        : 'https://us-central1-production-195315.cloudfunctions.net/corsProxy';

    const reqString = new XMLSerializer().serializeToString(reqBody);

    return axios({
      method: 'POST',
      url: `${proxyUrl}/regsaver?url=${process.env.REACT_APP_ENV}`,
      data: reqString,
      headers: {
        Accept: 'application/xml',
        'Content-type': 'application/xml',
      },
    })
      .then(xml => {
        const totalQuote = new DOMParser()
          .parseFromString(xml.data, 'text/xml')
          .getElementsByTagName('Amount');

        return Object.values(totalQuote).find(
          el => el.getAttribute('Type') === 'TotalPremium'
        );
      })
      .then(result => ({ [id]: result.textContent }))
      .catch(error => ({ error: error }))
      .finally(() => setIsInFlight(false));
  };

  //
  // ─── HELPERS ────────────────────────────────────────────────────────────────────
  //

  // Check whether there's already an address
  const findAddress = data => {
    const findAddress = Object.values(data).find(
      reg => !!reg.address && !!reg.city && !!reg.state && !!reg.zip
    );

    return (
      (!!findAddress && {
        address: findAddress.address,
        city: findAddress.city,
        state: findAddress.state,
        zip: findAddress.zip,
      }) ||
      defaultAddress
    );
  };

  //
  // ─── EVENT LISTENERS ────────────────────────────────────────────────────────────
  //

  // TODO calculate button could be component,
  // using isInFlight state + passing address as props
  const handleCalculate = async e => {
    e.preventDefault();

    // When either the required fees or address are missing values,
    // do not make requests for insurance quotes.
    if (
      !checkCompleteRequiredFees(requiredFields) ||
      !checkCompleteAddress(address)
    ) {
      setAddressError(true);
    } else {
      setIsInFlight(true);
      setAddressError(false);
      setResetQuotes(false);

      // Hidden input elements are inside classic signup flow - fees step
      Object.keys(address).map(key =>
        document
          .getElementById(`regsaver_${key}`)
          .setAttribute('value', address[key])
      );

      // When there are multiple registrants, multiple requests will be sent
      // Due to the way state is replaced (instead of merged) in Hooks,
      // all requests should be complete, before setting state for quotes
      return Promise.all(
        Object.keys(totalSignupFeesById).map(key => {
          const quote = buildXMLQuote(
            registrantsById[key],
            totalSignupFeesById[key],
            address
          );
          return fetchQuote(quote, key);
        })
      ).then(data => {
        let quotesObj = {};
        data.map(d => (quotesObj = { ...quotesObj, ...d }));
        if (Object.keys(quotesObj).indexOf('error') >= 0) {
          setinvalidData(true);
          setQuotesById({});
        } else {
          setinvalidData(false);
          setQuotesById(quotesObj);
        }
      });
    }
  };

  const handleAddress = e => {
    // TODO Find optimal event listener for address fields
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const handleResetQuotes = () => {
    setResetQuotes(true);
  };

  const updateFeesStep = e => {
    const { name, value } = e.target;

    // Hidden input elements are inside classic signup flow - fees step
    document.getElementById(`regsaver_${name}`).setAttribute('value', value);
  };

  return (
    <>
      <form>
        <div className="Grid Grid--withGutter u-spaceTopMd">
          <div className="Grid-cell u-sizeFull u-sm-size1of4">
            <label className="FieldGroup-label" htmlFor="address">
              Street Address
            </label>
            <input
              className="Input"
              defaultValue={ address.address }
              id="address"
              name="address"
              onBlur={ updateFeesStep }
              onKeyUp={ handleAddress }
              type="text"
            />
          </div>

          <div className="Grid-cell u-sizeFull u-sm-size1of4">
            <div className="FieldGroup">
              <label className="FieldGroup-label" htmlFor="city">
                City
              </label>
              <input
                className="Input"
                defaultValue={ address.city }
                id="city"
                name="city"
                onBlur={ updateFeesStep }
                onKeyUp={ handleAddress }
                type="text"
              />
            </div>
          </div>

          <div className="Grid-cell u-size1of6 u-sm-size1of4">
            <label className="FieldGroup-label" htmlFor="state">
              State
            </label>
            <div className="SelectBox">
              <select
                className="SelectBox-options"
                id="state"
                name="state"
                onBlur={ updateFeesStep }
                onChange={ handleAddress }
                value={ address.state }
              >
                <option value="" />
                { states.map(state => (
                  <option key={ state } value={ state }>
                    { state }
                  </option>
                )) }
              </select>
            </div>
          </div>

          <div className="Grid-cell u-sizeFull u-sm-size1of4">
            <div className="FieldGroup">
              <label className="FieldGroup-label" htmlFor="zip">
                Zip
              </label>
              <input
                className="Input"
                defaultValue={ address.zip }
                id="zip"
                maxLength="10"
                name="zip"
                onBlur={ updateFeesStep }
                onKeyUp={ handleAddress }
                size="10"
                type="text"
              />
            </div>
          </div>
        </div>

        <button
          className="Registration__large-button--primary"
          disabled={ isInFlight }
          onClick={ handleCalculate }
        >
          Calculate
        </button>

        { addressError && (
          <ErrorMessage message="Please fill out all required fields." />
        ) }
        { invalidData && (
          <ErrorMessage message="Unable to calculate. Please check the information provided and the Zip code." />
        ) }
      </form>

      <div className="Grid Grid-withGutter u-spaceTopLg">
        <Options
          resetQuotes={ resetQuotes }
          quotesById={ quotesById }
          totalSignupFeesById={ totalSignupFeesById }
        />
        <Logos />
      </div>
      <Disclaimer />
    </>
  );
};

export default Address;
