import { radios, removeCurrency, textFields } from './calculateFees';

const keyedObj = {};

const validateTextField = (value, type) =>
  type === 'text' &&
  !!value.length &&
  typeof parseFloat(removeCurrency(value)) === 'number';

const buildRequiredFields = () => {
  Object.values([...radios, ...textFields])
    .filter(v => v.required)
    .forEach(v => {
      if (v.type === 'text') {
        return (keyedObj[v.name] = validateTextField(v.value, v.type));
      } else {
        // Handle checked radio buttons, where any one group contains one
        // checked radio. Once a checked radio has been found in a radio group,
        // don't set the value to false, the keyed object's value should still
        // be set to true
        if (!!keyedObj[v.name] && !v.checked) {
          // The keyed object already has a value of true, do not overwrite the
          // value to be false.
          return (keyedObj[v.name] = true);
        } else {
          return (keyedObj[v.name] = v.checked);
        }
      }
    });
  return keyedObj;
};

const validateRequiredFields = (event, requiredFields) => {
  const { checked, name, type, value } = event.target;
  requiredFields[name] = checked || validateTextField(value, type);
  return requiredFields;
};

export { buildRequiredFields, validateRequiredFields, validateTextField };
