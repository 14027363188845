import React from 'react';

const Logos = () => (
  <div className="Grid-cell u-sizeFull u-sm-size1of3">
    <div className="Grid Grid--alignMiddle">
      <div className="Grid-cell u-sizeFit u-sm-sizeFull u-textCenter u-spaceTopLg u-spaceBottomLg">
        <img
          alt="Regsaver"
          className="Registration__Regsaver-image"
          src="/images/Regsaver.png"
        />
      </div>
    </div>
  </div>
);

export default Logos;
