import React from 'react';

const Disclaimer = () => (
  <div className="u-spaceTopXl Checkbox">
    <p>
      <em>
        Accepting coverage constitutes my electronic signature. I confirm that I have read, understand and agree to the{ ' ' }
        <a
          href="https://verticalinsure.com/legal/notices-and-disclosures-registration-cancellation"
          rel="noreferrer"
          target="_blank"
        >
          insurance notices and disclosures
        </a>
        .
      </em>
    </p>
  </div>
);

export default Disclaimer;
