/**
 * @function buildRegistrantsById
 * Get registrants by id, for keeping track of fee totals per player
 *
 * @param {Array} registrants
 * @returns Registrants by ID keyed object
 */
export const buildRegistrantsById = registrants => {
  const byId = {};
  registrants.map(r => (byId[r.id] = r));
  return byId;
};
