import React, { useEffect, useState, useCallback } from 'react';

import Address from 'components/Address';

import RegistrantsContext from 'context/Registrants';

import { radios, textFields, totalsById } from 'utils/calculateFees';
import { buildRegistrantsById } from 'utils/registrants';
import {
  buildRequiredFields,
  validateRequiredFields,
} from 'utils/validateRequiredFields';

const App = () => {
  const [registrantsById, setRegistrantsById] = useState({});
  const [requiredFields, setRequiredFields] = useState({});
  const [totalRegistrants, setTotalRegistrants] = useState({});
  const [totalSignupFeesById, setTotalSignupFeesById] = useState({});

  //
  // ─── EVENT LISTENERS ────────────────────────────────────────────────────────────
  //

  const handleTotalFees = () => {
    const totals = totalsById(registrantsById, totalRegistrants);
    setTotalSignupFeesById(totals);
  };

  const handleRequiredFields = event => {
    const currentRequiredFields = validateRequiredFields(event, requiredFields);
    setRequiredFields(currentRequiredFields);

    handleTotalFees();
  };

  useEffect(() => {
    fetchRegistrants();

    // If any required fees were previously selected, run the totals again
    handleTotalFees();

    // This is for Classic
    radios.forEach(input => {
      input.addEventListener('click', handleRequiredFields);
    });
    textFields.forEach(input => {
      input.addEventListener('blur', handleRequiredFields);
    });

    return () => {
      setRequiredFields(buildRequiredFields());

      radios.forEach(input => {
        input.removeEventListener('click', handleRequiredFields);
      });
      textFields.forEach(input => {
        input.removeEventListener('blur', handleRequiredFields);
      });
    };
  }, [requiredFields, totalRegistrants]);

  //
  // ─── API CALLS ──────────────────────────────────────────────────────────────────
  //

  const fetchRegistrants = () => {
    // Workaround for running the app outside of Classic
    if (window.TeamSnap.registrants.length) {
      const registrants = JSON.parse(window.TeamSnap.registrants);

      setRegistrantsById(buildRegistrantsById(registrants));
      setTotalRegistrants(registrants.length);
    }
  };

  return (
    <RegistrantsContext.Provider value={ registrantsById }>
      <div className="Registration__section">
        <h3 className="Registration__section-title">Protect Your Investment</h3>
        <div className="Registration__fee">
          <h4 className="Registration__fee-header u-spaceBottomSm">
            Get Reimbursed up to 100%
            <span className="u-colorNegative">
              { ' ' }
              * <small>(Required)</small>
            </span>
          </h4>
          <div className="Registration__fee-body">
            <p>
              <strong>
                What if your child becomes sick or injured?
                <br />
                What if you get called to active military service?
              </strong>
            </p>{ ' ' }
            <p>
              A Registration Saver (
              <a
                href="https://www.registrationsaver.com/youth-sports/"
                rel="noopener noreferrer"
                target="_blank"
              >
                RegSaver
              </a>
              ) policy can cover your registration fees should your child miss their
              season, or a portion of it, due to a covered peril.{ ' ' }
              <strong>Policies start as low as $7.</strong>
              <br />
            </p>
            <p>
              Enter your address below to calculate your insurance cost.{ ' ' }
              <strong>NOTE: if you are registering for a camp, tournament or showcase
              event you will need to purchase a Single Event Policy by visiting
              this link instead{ ' ' }
              </strong>
              <a
                href="https://www.registrationsaver.com/teamsnap"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.registrationsaver.com/teamsnap
              </a>
            </p>
            <Address
              requiredFields={ requiredFields }
              totalSignupFeesById={ totalSignupFeesById }
            />
          </div>
        </div>
      </div>
    </RegistrantsContext.Provider>
  );
};

export default App;
