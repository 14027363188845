/**
 * Check whether any required fees have not been selected
 */
export const checkCompleteRequiredFees = fees => {
  const completeRequiredFees = Object.values(fees).filter(f => f === false);
  return completeRequiredFees.length === 0;
};

/**
 * Check whether any address fields have not been inputted
 */
export const checkCompleteAddress = addr => {
  const completeAddressFields = Object.values(addr).filter(v => v.length === 0);
  return completeAddressFields.length === 0;
};
