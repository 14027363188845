import React from 'react';

const ErrorMessage = ({ message }) => (
  <div className="Feedback Feedback--negative" role="alert">
    <div className="Feedback-icon">
      <svg viewBox="0 0 960 960" role="presentation" className="Icon">
        <path d="M948 823c8 11.3 12 23.7 12 37 0 18-6.3 33.2-19 45.5S913.3 924 896 924H64c-23.3 0-41.7-10.3-55-31-12.7-20-13-41-1-63L424 61c12-22 30.7-33 56-33s44 11 56 33l412 762zM478 610h3c9.3 0 18-3.8 26-11.5s12.7-16.5 14-26.5c8-43.3 18.7-126.3 32-249 .7-8.7-1.8-17-7.5-25s-13.2-13-22.5-15c-28-5.3-56.3-5.3-85 0-8.7 2-16.2 7-22.5 15s-9.2 16.3-8.5 25c13.3 122.7 24 205.7 32 249 1.3 10 6 18.8 14 26.5s16.3 11.5 25 11.5zm2 227c20 0 37-7 51-21s21-31 21-51-7-37-21-51-31-21-51-21-37 7-51 21-21 31-21 51 7 37 21 51 31 21 51 21z" />
      </svg>
    </div>
    <h5 className="u-colorInfo Feedback-title">There was a problem</h5>
    <span>{ message }</span>
  </div>
);

export default ErrorMessage;
