/**
 * @function eventTracking
 *
 * Add a new event tracking object to Google Analytics.
 * The `dataLayer` array is generated by GA script,
 * and attached to the global `window` object.
 *
 * @param {Array} dataLayer
 * @param {String} action
 */
export const eventTracking = (dataLayer, action) =>
  dataLayer.push({
    event: 'Regsaver',
    eventCategory: 'Regsaver',
    eventAction: action,
    eventLabel: action,
  });
