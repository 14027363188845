// https://developer.mozilla.org/en-US/docs/Web/API/Document_object_model/How_to_create_a_DOM_tree
// https://developer.mozilla.org/en-US/docs/Web/API/Document/createTextNode

// Using ternary expression, in order for tests to run successfully
const user_email = window.TeamSnap ? window.TeamSnap.user_email : '';

export default (registrant, totalFees, address) => {
  const doc = document.implementation.createDocument('', '', null);

  const policySpecificationElem = doc.createElement('PolicySpecification');
  const policyElem = doc.createElement('Policy');

  const eventTypeElem = doc.createElement('EventType');
  const typeElem = doc.createElement('Type');
  const typeText = doc.createTextNode('Multiple');

  const travelersElem = doc.createElement('Travelers');
  const travelerElem = doc.createElement('Traveler');
  const tripCostElem = doc.createElement('TripCost');
  const tripCostText = doc.createTextNode(totalFees);
  const emailElem = doc.createElement('Email');
  const emailText = doc.createTextNode(user_email);
  // <First> and <Last> are children
  // of <TravelerName> and <PolicyHolder>
  const firstElem = doc.createElement('First');
  const lastElem = doc.createElement('Last');

  const addressElem = doc.createElement('Address');
  const streetElem = doc.createElement('Street');
  const cityElem = doc.createElement('City');
  const stateElem = doc.createElement('State');
  const zipElem = doc.createElement('Zip');
  const countryElem = doc.createElement('Country');
  const countryText = doc.createTextNode('US');

  const policyHolderNameElem = doc.createElement('PolicyHolderName');
  const policyHolderNameFirstText = doc.createTextNode(registrant.first);
  const policyHolderNameLastText = doc.createTextNode(registrant.last);

  const tripElem = doc.createElement('Trip');
  const departureDateElem = doc.createElement('DepartureDate');
  const returnDateElem = doc.createElement('ReturnDate');
  const initialTripDepositDateElem = doc.createElement('InitialTripDepositDate');
  const finalPaymentDateElem = doc.createElement('FinalPaymentDate');
  // TODO refactor date calculations out of builder
  const today = new Date();
  const tomorrow = new Date().setDate(today.getDate() + 1);
  const maxDate = new Date().setDate(today.getDate() + 364);
  const departureDateText = doc.createTextNode(
    new Date(tomorrow).toISOString().substring(0, 10)
  );
  const returnDateText = doc.createTextNode(
    new Date(maxDate).toISOString().substring(0, 10)
  );
  const initialTripDepositDateText = doc.createTextNode(
    today.toISOString().substring(0, 10)
  );
  const finalPaymentDateText = doc.createTextNode(
    today.toISOString().substring(0, 10)
  );

  typeElem.appendChild(typeText);
  eventTypeElem.appendChild(typeElem);

  travelersElem.appendChild(travelerElem);
  travelerElem.appendChild(tripCostElem).appendChild(tripCostText);
  travelerElem.appendChild(emailElem).appendChild(emailText);

  const newTravelerNameElem = doc.createElement('TravelerName');
  travelerElem.appendChild(newTravelerNameElem);
  newTravelerNameElem
    .appendChild(doc.createElement('First'))
    .appendChild(doc.createTextNode(registrant.first));
  newTravelerNameElem
    .appendChild(doc.createElement('Last'))
    .appendChild(doc.createTextNode(registrant.last));

  travelersElem.appendChild(addressElem);
  addressElem
    .appendChild(streetElem)
    .appendChild(doc.createTextNode(address.address));
  addressElem
    .appendChild(cityElem)
    .appendChild(doc.createTextNode(address.city));
  addressElem
    .appendChild(stateElem)
    .appendChild(doc.createTextNode(address.state));
  addressElem.appendChild(zipElem).appendChild(doc.createTextNode(address.zip));
  addressElem.appendChild(countryElem).appendChild(countryText);

  policyHolderNameElem
    .appendChild(firstElem)
    .appendChild(policyHolderNameFirstText);
  policyHolderNameElem
    .appendChild(lastElem)
    .appendChild(policyHolderNameLastText);

  tripElem.appendChild(departureDateElem).appendChild(departureDateText);
  tripElem.appendChild(returnDateElem).appendChild(returnDateText);
  tripElem
    .appendChild(initialTripDepositDateElem)
    .appendChild(initialTripDepositDateText);
  tripElem.appendChild(finalPaymentDateElem).appendChild(finalPaymentDateText);

  policyElem
    .appendChild(eventTypeElem)
    .insertAdjacentElement('afterend', travelersElem)
    .insertAdjacentElement('afterend', policyHolderNameElem)
    .insertAdjacentElement('afterend', tripElem);
  policySpecificationElem.appendChild(policyElem);
  doc.appendChild(policySpecificationElem);

  return doc;
};
