/**
 * Radio input fields for signup fees in the registration form
 * `data-fee` attribute contains the fee value,
 * and is used for calculating totals
 */
const radios = document.querySelectorAll('input[data-fee][type="radio"]');

/**
 * Free form text input fields for signup fees in the registration form
 * `data-freeform-fee` attribute is used to select signup fees
 * The native `value` attribute is used for calculating totals
 */
const textFields = document.querySelectorAll(
  'input[data-freeform-fee][type="text"]'
);

/**
 * @function removeCurrency
 *
 * Remove any currency symbols from user input, or from default values
 *
 * @param {string} value Target from blur() event
 */
const removeCurrency = value => value.replace(/^\D+/g, '');

/**
 * @function currentTotalFees
 *
 * Calculates a running total of signup fees, based on user selections.
 *
 * Any fee that is flagged as required is used for the premium quote and policy
 * Registration fees, where fees are not specifed per player, will be divided
 * by total number of registrants.
 *
 * @param {string} [playerId] Current registrant for getting the total
 * @param {number} [numberOfRegistrants=1] Total registrants
 * @param {Object} [radioInputs=radios] Input radio fields on the fees step
 * @param {Object} [textFieldInputs=textFields] Input text fields on fees step
 * @returns Current total for signup fees
 */
const currentTotalFees = (
  playerId,
  numberOfRegistrants = 1,
  radioInputs = radios,
  textFieldInputs = textFields
) => {
  let total = 0;
  Object.values([...radioInputs, ...textFieldInputs])
    .filter(r => r.required)
    .forEach(input => {
      // Get registration fee from free form text fields
      // Text inputs can only be used for registration fees
      if (input.type === 'text') {
        total +=
          parseFloat(removeCurrency(input.value) || 0) / numberOfRegistrants;
      } else {
        // Get player fees from radio input fields
        // If there's a playerId, then perPlayer is already true
        if (input.checked && input.dataset.playerId === playerId) {
          total += parseFloat(input.dataset.fee);
        }
        // Get registration fees from radio input fields
        else if (input.checked && input.dataset.perPlayer === 'false') {
          total += parseFloat(input.dataset.fee) / numberOfRegistrants;
        }
      }
    });
  // Format currency, rounded up to 2 decimal places
  return total.toFixed(2);
};

/**
 * @function totalsById
 *
 * Map the current total of signup fees to the registrant's ID
 *
 * @param {object} registrantsById
 * @param {number} totalRegistrants
 * @returns Object containing total fees by registrant ID
 */
const totalsById = (registrantsById, totalRegistrants) => {
  const totalsById = {};
  Object.keys(registrantsById).map(
    key => (totalsById[key] = currentTotalFees(key, totalRegistrants))
  );
  return totalsById;
};

export { currentTotalFees, radios, removeCurrency, textFields, totalsById };
